<template>
  <div>
    <router-link :to="`/resources/${article.slug}`" class="d-block">
      <base-img :height="prominent ? 400 : 250" :src="src" flat tile>
        <v-row class="ma-0 fill-height" align="end" justify="end">
          <!-- <v-sheet
            class="pa-2 d-inline-flex align-center justify-center"
            color="primary"
            dark
            tile
            height="40"
            width="40"
          >
            <v-icon v-if="icon"> {{ icon }} </v-icon>
          </v-sheet> -->
        </v-row>
      </base-img>
    </router-link>

    <v-theme-provider :dark="!$vuetify.theme.isDark">
      <v-sheet
        :dark="!$vuetify.theme.isDark"
        :light="$vuetify.theme.isDark"
        class="d-flex"
        tile
        max-width="100%"
      >
        <!-- <div class="grow pa-2 body-2">
          <div class="d-flex align-center fill-height">
            <span class="px-2" v-text="date" />
            /
            <span class="px-2" v-text="category" />

            <template v-if="$vuetify.breakpoint.mdAndUp">
              /
              <span class="px-2" v-text="`${comments} Comments`" />
            </template>
          </div>
        </div> -->
      </v-sheet>
    </v-theme-provider>

    <div>
      <!--<v-tooltip bottom>
         <template v-slot:activator="{ on, attrs }">
          <base-title
            :title="title"
            class="text-truncate text-uppercase"
            v-bind="attrs"
            v-on="on" /></template
        ><div class="custom-tooltip-content">{{ title }}</div></v-tooltip> -->

      <!-- <base-title :title="title"></base-title> -->
      <div style="height: 90px" class="py-3">
        <p>
          <b>{{ title }}</b>
        </p>
      </div>
      <base-body
        :text="!html ? truncatedText : undefined"
        :html="html"
        space="0"
        customText="blackText"
      />
    </div>

    <v-btn v-if="readMore" class="font-weight-bold" text> Read More </v-btn>

    <v-divider v-if="divider" class="mt-6" />
  </div>
</template>

<script>
export default {
  name: "NewsCard",

  props: {
    article: {
      type: Object,
      default: () => ({}),
    },
    category: String,
    comments: [Number, String],
    date: String,
    divider: Boolean,
    html: String,
    icon: String,
    prominent: Boolean,
    readMore: Boolean,
    src: String,
    text: String,
    title: String,
    truncate: [Number, String],
  },

  computed: {
    truncatedText() {
      if (!this.text) return "";

      const truncate = Number(this.truncate);

      return this.text.length > truncate
        ? this.text.slice(0, truncate) + " [...]"
        : this.text;
    },
  },
};
</script>

<style scoped>
</style>